import {appDefIds, envTypes, urlMap} from '../services/constants';
import {ILanguageStatus} from '../services/onboarding-api';

import {DSLanguage} from '../types/wix-types';

export const resolveAppUrl = (appDefId: string): string => {
  if (appDefId in appDefIds) {
    return urlMap[appDefIds[appDefId]];
  }
  const envType = getEditorType();
  return urlMap[envType];
};

function getEditorType(): envTypes {
  return self.commonConfig?.brand === 'editorx' ? envTypes.editorx : envTypes.default;
}

export function enrichLanguage(language: ILanguageStatus): DSLanguage {
  return {
    ...language,
    languageCode: language.code,
  };
}

export enum ModalResolveValues {
  CANCEL = 'CANCEL',
  COMPLETE = 'COMPLETE',
}
